define("ember-i18n/services/i18n", ["exports", "ember", "ember-i18n/stream", "ember-i18n/utils/locale", "ember-i18n/utils/add-translations", "ember-i18n/utils/get-locales"], function (exports, _ember, _emberI18nStream, _emberI18nUtilsLocale, _emberI18nUtilsAddTranslations, _emberI18nUtilsGetLocales) {
  "use strict";

  var get = _ember["default"].get;
  var makeArray = _ember["default"].makeArray;
  var computed = _ember["default"].computed;

  var Parent = _ember["default"].Service || _ember["default"].Object;

  // @public
  exports["default"] = Parent.extend(_ember["default"].Evented, {

    // @public
    // The user's locale.
    locale: null,

    // @public
    // A list of found locales.
    locales: computed(_emberI18nUtilsGetLocales["default"]),

    // @public
    //
    // Returns the translation `key` interpolated with `data`
    // in the current `locale`.
    t: function t(key) {
      var data = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

      var locale = this.get('_locale');
      _ember["default"].assert("I18n: Cannot translate when locale is null", locale);
      var count = get(data, 'count');

      var defaults = makeArray(get(data, 'default'));

      defaults.unshift(key);
      var template = locale.getCompiledTemplate(defaults, count);

      if (template._isMissing) {
        this.trigger('missing', this.get('locale'), key, data);
      }

      return template(data);
    },

    // @public
    exists: function exists(key) {
      var data = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

      var locale = this.get('_locale');
      _ember["default"].assert("I18n: Cannot check existance when locale is null", locale);
      var count = get(data, 'count');

      var translation = locale.findTranslation(makeArray(key), count);
      return _ember["default"].typeOf(translation.result) !== 'undefined';
    },

    // @public
    addTranslations: function addTranslations(locale, translations) {
      (0, _emberI18nUtilsAddTranslations["default"])(locale, translations, this.container);
      this._addLocale(locale);

      if (this.get('locale').indexOf(locale) === 0) {
        this.get('_locale').rebuild();
      }
    },

    // @private
    //
    // adds a runtime locale to the array of locales on disk
    _addLocale: function _addLocale(locale) {
      this.get('locales').addObject(locale);
    },

    _locale: _ember["default"].computed('locale', function () {
      var locale = this.get('locale');
      return locale ? new _emberI18nUtilsLocale["default"](this.get('locale'), this.container) : null;
    }),

    _buildLocaleStream: _ember["default"].on('init', function () {
      var _this = this;

      this.localeStream = new _emberI18nStream["default"](function () {
        return _this.get('locale');
      });
    }),

    _notifyLocaleStream: _ember["default"].observer('locale', function () {
      this.localeStream.value(); // force the stream to be dirty
      this.localeStream.notify();
    })

  });
});