define('ember-cli-bootstrap-datetimepicker/components/bs-datetimepicker', ['exports', 'ember', 'ember-cli-bootstrap-datetimepicker/templates/components/bs-datetimepicker'], function (exports, _ember, _emberCliBootstrapDatetimepickerTemplatesComponentsBsDatetimepicker) {
  'use strict';

  var $ = _ember['default'].$;
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var defaults = $.fn.datetimepicker.defaults;

  exports['default'] = Component.extend({
    layout: _emberCliBootstrapDatetimepickerTemplatesComponentsBsDatetimepicker['default'],
    tagName: 'div',
    classNames: ['input-group date'],

    iconClasses: computed('isTime', function () {
      if (this.get('isTime')) {
        return this.getWithDefault('config.icons.time', defaults.icons.time);
      }

      return this.getWithDefault('config.icons.date', defaults.icons.date);
    }),

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var icons = {
        clear: this.getWithDefault('config.icons.clear', defaults.icons.clear),
        close: this.getWithDefault('config.icons.close', defaults.icons.close),
        date: this.getWithDefault('config.icons.date', defaults.icons.date),
        down: this.getWithDefault('config.icons.down', defaults.icons.down),
        next: this.getWithDefault('config.icons.next', defaults.icons.next),
        previous: this.getWithDefault('config.icons.previous', defaults.icons.previous),
        time: this.getWithDefault('config.icons.time', defaults.icons.time),
        today: this.getWithDefault('config.icons.today', defaults.icons.today),
        up: this.getWithDefault('config.icons.up', defaults.icons.up)
      };

      this.$().datetimepicker({
        date: this.getWithDefault('date', defaults.defaultDate),
        focusOnShow: this.getWithDefault('focusOnShow', defaults.focusOnShow),
        format: this.getWithDefault('format', defaults.format),
        icons: icons,
        locale: this.getWithDefault('locale', defaults.locale),
        maxDate: this.getWithDefault('maxDate', defaults.maxDate),
        minDate: this.getWithDefault('minDate', defaults.minDate),
        showClear: this.getWithDefault('showClear', defaults.showClear),
        showClose: this.getWithDefault('showClose', defaults.showClose),
        showTodayButton: this.getWithDefault('showTodayButton', defaults.showTodayButton),
        useCurrent: this.getWithDefault('useCurrent', false),
        viewDate: this.getWithDefault('viewDate', defaults.viewDate),
        viewMode: this.getWithDefault('viewMode', defaults.viewMode)
      }).on('dp.change', function (e) {
        // Convert moment to js date or default to null
        var newDate = e.date && e.date.toDate() || null;

        _this.set('date', newDate);
        _this.sendAction('change', newDate);
      });

      this.addObserver('date', function () {
        this.$().data('DateTimePicker').date(this.get('date'));
      });

      this.addObserver('maxDate', function () {
        this.$().data('DateTimePicker').maxDate(this.get('maxDate'));
      });

      this.addObserver('minDate', function () {
        this.$().data('DateTimePicker').minDate(this.get('minDate'));
      });
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.removeObserver('date');
      this.removeObserver('maxDate');
      this.removeObserver('minDate');

      this.$().data('DateTimePicker').destroy();
    }
  });
});