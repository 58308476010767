define('ember-cli-dates/helpers/time-format', ['exports', 'ember', 'moment', 'ember-cli-dates/utils/time-locale', 'ember-cli-dates/utils/valid-args'], function (exports, _ember, _moment, _emberCliDatesUtilsTimeLocale, _emberCliDatesUtilsValidArgs) {
  'use strict';

  function timeFormat(date, optionalFormat, optionalLocale) {
    (0, _emberCliDatesUtilsValidArgs['default'])(arguments, 'time-format');

    if (_ember['default'].isBlank(date)) {
      return '';
    }

    var locale = (0, _emberCliDatesUtilsTimeLocale['default'])(optionalLocale),
        format = 'LL';

    if (_ember['default'].typeOf(optionalFormat) === 'string') {
      format = optionalFormat;
    }

    return (0, _moment['default'])(date).locale(locale).format(format);
  }

  exports.timeFormat = timeFormat;
  exports['default'] = _ember['default'].Handlebars.makeBoundHelper(timeFormat);
});