define('ember-cli-dates/utils/time-locale', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  'use strict';

  exports['default'] = timeLocale;

  function timeLocale(optionalLocale) {
    if (_ember['default'].typeOf(optionalLocale) === 'string') {
      return optionalLocale;
    }

    return (0, _moment['default'])().locale();
  }
});