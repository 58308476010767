define('ember-cli-dates/utils/valid-args', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = validArgs;

  function validArgs(args, helper) {
    if (_ember['default'].isEmpty(args) || args.length === 1) {
      throw new _ember['default'].Error('[ember-cli-dates:' + helper + '] Invalid number of arguments, expected at least 1');
    }
  }
});