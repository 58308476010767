define('ember-cli-dates/helpers/time-ago-in-words', ['exports', 'ember', 'moment', 'ember-cli-dates/utils/time-locale', 'ember-cli-dates/utils/valid-args'], function (exports, _ember, _moment, _emberCliDatesUtilsTimeLocale, _emberCliDatesUtilsValidArgs) {
  'use strict';

  function timeAgoInWords(date, optionalLocale) {
    (0, _emberCliDatesUtilsValidArgs['default'])(arguments, 'time-ago-in-words');

    if (_ember['default'].isBlank(date)) {
      return '';
    }

    var locale = (0, _emberCliDatesUtilsTimeLocale['default'])(optionalLocale);

    return (0, _moment['default'])(date).locale(locale).fromNow();
  }

  exports.timeAgoInWords = timeAgoInWords;
  exports['default'] = _ember['default'].Handlebars.makeBoundHelper(timeAgoInWords);
});