define('ember-cli-html5-validation/ext/select', ['exports', 'ember', 'ember-cli-html5-validation/mixins/validatable-input'], function (exports, _ember, _emberCliHtml5ValidationMixinsValidatableInput) {
  'use strict';

  /**
   * @namespace Ember
   * @class Select
   */
  // Ember.Select is removed in v2.0
  if (!!_ember['default'].Select) {
    _ember['default'].Select.reopen(_emberCliHtml5ValidationMixinsValidatableInput['default']);
  }
});