define('ember-cli-dates/helpers/month-and-day', ['exports', 'ember', 'moment', 'ember-cli-dates/utils/time-locale', 'ember-cli-dates/utils/valid-args'], function (exports, _ember, _moment, _emberCliDatesUtilsTimeLocale, _emberCliDatesUtilsValidArgs) {
  'use strict';

  function monthAndDay(date, optionalLocale) {
    (0, _emberCliDatesUtilsValidArgs['default'])(arguments, 'day-of-the-week');

    if (_ember['default'].isBlank(date)) {
      return '';
    }

    var locale = (0, _emberCliDatesUtilsTimeLocale['default'])(optionalLocale);

    return (0, _moment['default'])(date).locale(locale).format('MMM Do');
  }

  exports.monthAndDay = monthAndDay;
  exports['default'] = _ember['default'].Handlebars.makeBoundHelper(monthAndDay);
});